export default {
  GET(state, data) {
    state.priceTier = { ...data }
  },
  LIST(state, data) {
    state.priceTiers = data
  },
  SET_FORM(state, data) {
    state.priceTiersForm = { ...data }
  },
}
