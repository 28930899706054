import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = 'settings/catalog/pricing-rules'
const update = (ctx, data) => axios.put(`${endpoint}/${data.id}`, data)
const setDefault = (ctx, data) => axios.post(`settings/catalog/pricing-rules/${data.id}/set-default`)
const setPosition = (ctx, data) => axios.post(`settings/catalog/pricing-rules/${data.pricing_rule}/set-position`, data)
const duplicate = (ctx, data) => axios.post(`settings/catalog/${data.id}/duplicate`)

const Actions = crudActions(endpoint)

export default {
  ...Actions,
  update,
  setDefault,
  setPosition,
  duplicate,
  getInThisCategoryPricingRules: ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get('settings/catalog/pricing-rules', { params: queryParams, paramsSerializer: params => stringify(params) })
      .then(response => {
        commit('SET_PRICING_RULE_LIST', response.data.data.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  getCurrenciesList: ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get('currencies', { params: queryParams, paramsSerializer: params => stringify(params) })
      .then(response => {
        commit('SET_CURRENCIES_LIST', response.data.data.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
