import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = 'price-tiers'
const setDefault = (ctx, data) => axios.post(`/price-tiers/${data.id}/set-default`)
const setPosition = (ctx, data) => axios.post(`/price-tiers/${data.price_tier}/set-position`, data)
const duplicate = (ctx, data) => axios.post(`/price-tier/${data.id}/duplicate`)

const Actions = crudActions(endpoint)

export default {
  ...Actions,
  setDefault,
  setPosition,
  duplicate,
  getInThisCategoryPriceTiers: ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get('price-tiers', { params: queryParams, paramsSerializer: params => stringify(params) })
      .then(response => {
        commit('SET_PRICE_TIER_LIST', response.data.data.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
