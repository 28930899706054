import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'pricing/discounts'
const update = (ctx, data) => axios.put(`/${endpoint}/${data.id}`, data)
const setPosition = (ctx, data) => axios.post(`/${endpoint}/${data.discount}/set-position`, data)
const duplicate = (ctx, data) => axios.post(`/${endpoint}/${data.id}/duplicate`)
const discountTypes = ({ commit }) => new Promise((resolve, reject) => {
  axios.get(`/${endpoint}/discount-types`)
    .then(response => {
      const { data } = response.data
      const mappedList = Object.keys(data)?.map(item => ({ id: item, name: data[item] }))
      commit('SET_DISCOUNT_TYPES', mappedList)
      resolve(response)
    })
    .catch(error => {
      console.error(error)
      reject(error)
    })
})
const getProducts = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  axios.get('/products', { params: queryParams })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

const moveToTrashed = (ctx, data) => axios.post(`/${endpoint}/move-to-trashed`, { ids: data })
const restoreFromTrashedList = (ctx, data) => axios.post(`/${endpoint}/restore-from-trashed`, { ids: data })

const Actions = crudActions(endpoint)

export default {
  ...Actions,
  update,
  setPosition,
  duplicate,
  discountTypes,
  getProducts,
  moveToTrashed,
  restoreFromTrashedList,
}
