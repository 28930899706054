export default {
  GET(state, data) {
    state.discount = { ...data }
  },
  LIST(state, data) {
    state.discounts = data
  },
  SET_DISCOUNT_TYPES(state, data) {
    state.discountTypes = data
  },
  SET_FORM(state, data) {
    state.discountsForm = { ...data }
  },
  SET_DISCOUNT_PRODUCTS_TABLE(state, data) {
    state.orderItemsRentalTable = { ...data }
  },
}
