export default {
  GET(state, data) {
    state.pricingRule = { ...data }
  },
  LIST(state, data) {
    state.pricingRules = data
  },
  SET_CURRENCIES_LIST(state, data) {
    state.currenciesList = data
  },
  SET_FORM(state, data) {
    state.pricingRulesForm = { ...data }
  },
}
