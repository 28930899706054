<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import pricingTiersModule from '@/store/settings/catalog/pricing-tiers'
import pricingRulesModule from '@/store/settings/catalog/pricing-rules'
import discounts from '@/store/settings/catalog/discounts'
import catalogPrice from '@/store/settings/catalog'

export default {
  name: 'Pricing',
  provide: {
    pricingTiersModuleName: 'price-tiers',
    pricingRulesModuleName: 'pricing-rules',
    discountsModuleName: 'discounts',
  },
  created() {
    this.$emit('updateMenu', 'settings-catalog-pricing')
  },
  setup() {
    const CATALOG_PRICE_MODULE = 'catalog-price'
    const MODULE_NAME = 'price-tiers'
    const MODULE_NAME_PRICE = 'pricing-rules'
    const MODULE_NAME_DISCOUNTS = 'discounts'

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, pricingTiersModule)
    if (!store.hasModule(MODULE_NAME_PRICE)) store.registerModule(MODULE_NAME_PRICE, pricingRulesModule)
    if (!store.hasModule(MODULE_NAME_DISCOUNTS)) store.registerModule(MODULE_NAME_DISCOUNTS, discounts)
    if (!store.hasModule(CATALOG_PRICE_MODULE)) store.registerModule(CATALOG_PRICE_MODULE, catalogPrice)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME_PRICE)) store.unregisterModule(MODULE_NAME_PRICE)
    })
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME_DISCOUNTS)) store.unregisterModule(MODULE_NAME_DISCOUNTS)
    })
  },
}
</script>
