import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'settings/pricing/costing-defaults'
const Actions = crudActions(endpoint)
const updateCatalogPricing = (ctx, data) => axios.post(endpoint, data)
const getCatalogPricing = ({ commit }) => new Promise((resolve, reject) => {
  axios.get(endpoint)
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
export default {
  getCatalogPricing,
  updateCatalogPricing,
  ...Actions,
}
